.App {
  text-align: center;
  width: 100vw !important;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #F1EBFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

p {
  font-family: "Inter" sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: auto;
  color: #5200FF;
}

.button {
  height: 40px !important;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #5200FF;
  border-radius: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Inter" sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: auto;
  cursor: pointer;
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}

.description {
  width: 240px !important;
  margin: 40px 0px !important;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-empty {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: 4px solid #5200FF;
  border-radius: 24px;
  margin-right: 8px;
}

.circle-full {
  width: 24px;
  height: 24px;
  background-color: #5200FF;
  border: 4px solid #5200FF;
  border-radius: 24px;
  animation-name: wink;
  animation-delay: 3s;
  animation-duration: 6s;
  animation-timing-function: linear;
}

@keyframes wink {
  2% {
    transform: translateX(0.5px);
  }

  4% {
    transform: translateX(-1px);
  }

  6% {
    transform: translateX(1px);
  }

  8% {
    transform: translateX(-1px);
  }

  10% {
    transform: translateX(1px);
  }

  12% {
    transform: translateX(-2px);
  }

  14% {
    transform: translateX(2px);
  }

  16% {
    transform: translateX(-2px);
  }

  18% {
    transform: translateX(2px);
  }

  20% {
    transform: translateX(-2px);
  }

  22% {
    transform: translateX(2px);
  }

  24% {
    transform: translateX(-2px);
  }

  26% {
    transform: translateX(2px);
  }

  28% {
    transform: translateX(-2px);
  }

  30% {
    transform: translateX(2px);
  }

  32% {
    transform: translateX(-4px);
  }

  34% {
    transform: translateX(4px);
  }

  36% {
    transform: translateX(-4px);
  }

  38% {
    transform: translateX(4px);
  }

  40% {
    transform: translateX(-4px);
  }

  42% {
    transform: translateX(4px);
  }

  44% {
    transform: translateX(-4px);
  }

  46% {
    transform: translateX(4px);
  }

  48% {
    transform: translateX(-4px);
  }

  50% {
    transform: translateX(4px);
  }

  52% {
    transform: translateX(-2px);
  }

  54% {
    transform: translateX(2px);
  }

  56% {
    transform: translateX(-2px);
  }

  58% {
    transform: translateX(2px);
  }

  60% {
    transform: translateX(-2px);
  }

  62% {
    transform: translateX(2px);
  }

  64% {
    transform: translateX(-2px);
  }

  66% {
    transform: translateX(2px);
  }

  68% {
    transform: translateX(-2px);
  }

  70% {
    transform: translateX(1px);
  }

  72% {
    transform: translateX(-1px);
  }

  74% {
    transform: translateX(1px);
  }

  76% {
    transform: translateX(-1px);
  }

  78% {
    transform: translateX(1px);
  }

  80% {
    transform: translateX(-0.5px);
  }

  96% {
    height: 24px;
  }

  98% {
    height: 0px;
  }

  100% {
    height: 24px;
  }

}